import React from "react";
import RelativityCard from "../Card/RelativityCard";
import RelativitySubCard from "../Card/RelativitySubCard";
import { BaseField } from "../Forms/BaseField/BaseField";
import {
  fiaRequestFormSchema,
  containsSpouseInfo,
  UpdateFIARequest,
} from "../Forms/FIARequestForm/FIARequestForm";
import { FIARequest } from "./configurations/types";
import { useState } from "react";
import { Callout } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Institution } from "../../types/Institution";
import { featureToggleStore } from "../../lib/featureToggles/FeatureToggleStore";

export interface FIARequestDetailsProps {
  fiaRequest: FIARequest;
  applicationId: string;
  validateFIARequestRequirements?: (
    applicationId: string,
    fiaRequestId: string,
    fiaRequest: FIARequest
  ) => Promise<void>;
  errors: {
    [key: string]: string[];
  };
  setFIARequest: (fiaRequest: FIARequest) => void;
  setIsActionExecuting?: (isAction: any) => void;
  institution?: Institution;
}

export const FIARequestDetails: React.FC<FIARequestDetailsProps> = ({
  fiaRequest,
  applicationId,
  validateFIARequestRequirements,
  errors,
  setIsActionExecuting,
  setFIARequest,
  institution,
}) => {
  const [showAnnotationWarning, setShowAnnotationWarning] = useState(false);
  const showAnnotationCharLimitWarning = (fiaRequest: FIARequest) => {
    setShowAnnotationWarning(
      fiaRequest.annotation && fiaRequest.annotation.length > 512 ? true : false
    );
  };

  return (
    <section className="fia-request-details-container">
      <RelativityCard headerContent="Details">
        <RelativitySubCard headerContent="Resident" columns={4}>
          <BaseField {...fiaRequestFormSchema.residentFullName(fiaRequest)} />
          <BaseField
            {...fiaRequestFormSchema.residentDateOfBirth(fiaRequest)}
          />
          <BaseField {...fiaRequestFormSchema.residentSSN(fiaRequest)} />
          <BaseField
            handleOnBlur={async () => {
              !!setIsActionExecuting && setIsActionExecuting(true);
              await UpdateFIARequest(
                fiaRequest,
                validateFIARequestRequirements
              ).finally(() => {
                !!setIsActionExecuting && setIsActionExecuting(false);
              });
            }}
            {...fiaRequestFormSchema.isResidentIncluded(fiaRequest)}
          />
        </RelativitySubCard>
        {containsSpouseInfo(fiaRequest) && (
          <RelativitySubCard headerContent="Spouse" columns={4}>
            <BaseField {...fiaRequestFormSchema.spouseFullName(fiaRequest)} />
            <BaseField
              {...fiaRequestFormSchema.spouseDateOfBirth(fiaRequest)}
            />
            <BaseField {...fiaRequestFormSchema.spouseSSN(fiaRequest)} />
            <BaseField
              handleOnBlur={async () => {
                !!setIsActionExecuting && setIsActionExecuting(true);
                await UpdateFIARequest(
                  fiaRequest,
                  validateFIARequestRequirements
                ).finally(() => {
                  !!setIsActionExecuting && setIsActionExecuting(false);
                });
              }}
              {...fiaRequestFormSchema.isSpouseIncluded(fiaRequest)}
            />
          </RelativitySubCard>
        )}
        <RelativitySubCard headerContent="Institution">
          <BaseField
            {...fiaRequestFormSchema.checklistItemId(
              fiaRequest,
              applicationId,
              setFIARequest,
              errors
            )}
          />
          <BaseField
            {...fiaRequestFormSchema.institutionId(
              fiaRequest,
              setFIARequest,
              errors
            )}
          />
          {institution && !institution.isActive && (
            <Callout
              className="fia-request-annotation-char-limit-warning"
              icon={IconNames.WARNING_SIGN}
              intent="warning"
            >
              {`This institution is inactive. Please update the institution to send the FIA request.`}
            </Callout>
          )}
          <BaseField
            {...fiaRequestFormSchema.institutionDefaultSendMethod(fiaRequest)}
          />
          <BaseField
            {...fiaRequestFormSchema.institutionDisplayNotes(fiaRequest)}
          />
          <BaseField
            {...fiaRequestFormSchema.institutionAccountNumbers_AsList(
              fiaRequest,
              setFIARequest,
              errors
            )}
          />
          <BaseField
            {...fiaRequestFormSchema.statementDateRangeStart(
              fiaRequest,
              setFIARequest,
              institution,
              validateFIARequestRequirements,
              errors
            )}
          />
          <BaseField
            {...fiaRequestFormSchema.statementDates(
              fiaRequest,
              setFIARequest,
              institution,
              validateFIARequestRequirements,
              errors
            )}
          />
          {featureToggleStore.featureToggles?.EnableStatementCycleEndDay && (
            <BaseField
              {...fiaRequestFormSchema.statementCycleEndDay(
                fiaRequest,
                setFIARequest,
                institution,
                validateFIARequestRequirements,
                errors
              )}
            />
          )}
        </RelativitySubCard>
        <RelativitySubCard>
          <BaseField
            handleOnBlur={async () => {
              showAnnotationCharLimitWarning(fiaRequest);
              await UpdateFIARequest(fiaRequest);
            }}
            {...fiaRequestFormSchema.annotation(
              fiaRequest,
              setFIARequest,
              errors
            )}
          />
          {showAnnotationWarning && (
            <Callout
              className="fia-request-annotation-char-limit-warning"
              icon={IconNames.WARNING_SIGN}
              intent="warning"
            >
              {`Annotation allows a maximum character limit of 512 characters. Please reduce the number of characters.`}
            </Callout>
          )}
        </RelativitySubCard>
      </RelativityCard>
    </section>
  );
};
