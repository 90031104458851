import { action, makeObservable, observable } from "mobx";
import { Asset } from "../../types/Application/Asset";
import { OwnedEntityViewerStore } from "./OwnedEntityViewerStore";

export interface AssetStore {
  addLocalAsset: (assetToAdd: Asset) => void;
  removeLocalAsset: (assetToRemove: Asset) => void;
  saveLocalAssets: () => void;
  localAssets?: Asset[];
}

export class AssetOwnedEntityStore
  extends OwnedEntityViewerStore
  implements AssetStore
{
  public localAssets?: Asset[] = [];

  constructor() {
    super();
    makeObservable(this, {
      localAssets: observable,
      addLocalAsset: action,
      removeLocalAsset: action,
      saveLocalAssets: action,
    });
  }

  public addLocalAsset(assetToAdd: Asset) {
    if (!this.localAssets) {
      this.localAssets = [];
    }

    const index = this.localAssets.findIndex(
      (asset) => asset.id === assetToAdd.id
    );
    if (index === -1) {
      // Asset not found, add the new Asset
      this.localAssets = [...this.localAssets, assetToAdd];
    } else {
      // Asset found, update the existing Asset
      this.localAssets = this.localAssets.map((asset, i) =>
        i === index ? assetToAdd : asset
      );
    }
  }

  public removeLocalAsset(assetToRemove: Asset) {
    if (!this.localAssets) {
      return;
    }
    this.localAssets = this.localAssets.filter(
      (x) => x.id !== assetToRemove.id
    );
  }

  public saveLocalAssets = async () => {
    const promisesToAwait = [];

    if (!!this.localAssets && this.localAssets.length > 0) {
      for (let i = 0; i < this.localAssets.length; i++) {
        const asset = this.localAssets[i];
        /**
         * Check for asset type on creation of new asset
         * Since form schemas and how it is set defers between application asset forms and owned entity component
         */
        if (asset.id === "new-asset" && asset.type) {
          promisesToAwait.push(
            this.createAndAwaitApplicationOwnedEntity({
              page: "asset",
              ownedEntity: asset,
            })
          );
        } else {
          promisesToAwait.push(
            this.updateAndAwaitApplicationOwnedEntity({
              page: "asset",
              ownedEntity: asset,
              ownedEntityId: asset.id,
            })
          );
        }
      }
      await this.awaitAndValidateOwnedEntityPromises(promisesToAwait, "asset");
      this.localAssets = [];
    }
  };
}

export const assetOwnedEntityStore = new AssetOwnedEntityStore();
