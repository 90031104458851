import { GridColumn } from "@ucl/library";
import GridStyleColumn from "../../Grid/Grid/types/GridStyleColumn";
import GridStyleFIARequestDueInColumn from "../../Grid/Grid/types/GridStyleFIARequestDueInColumn";

export const fiaRequestsGridColumnsConfiguration: GridColumn[] = [
  {
    field: "fileNumber",
    headerText: "File Number",
    type: "string",
  } as GridColumn,
  new GridStyleColumn({
    field: "requestNumber_AsString",
    headerText: "Request Number",
    type: "string",
  }),
  {
    field: "institutionName",
    headerText: "Institution Name",
    type: "string",
  } as GridColumn,
  {
    field: "lastSubmittedAt",
    headerText: "Submitted Date",
    type: "datetime",
    format: { format: "MM/dd/yyyy hh:mm:ss a", type: "datetime" },
    allowSearching: false,
  } as GridColumn,
  {
    field: "estimatedReturnDate",
    headerText: "Estimated Return Date",
    type: "datetime",
    format: { format: "MM/dd/yyyy hh:mm:ss a", type: "datetime" },
    allowSearching: false,
  } as GridColumn,
  {
    field: "markedAsReceivedAt",
    headerText: "Received Date",
    type: "datetime",
    format: { format: "MM/dd/yyyy hh:mm:ss a", type: "datetime" },
    allowSearching: false,
  } as GridColumn,
  {
    field: "canceledAt",
    headerText: "Canceled Date",
    type: "datetime",
    format: { format: "MM/dd/yyyy hh:mm:ss a", type: "datetime" },
    allowSearching: false,
  } as GridColumn,
  new GridStyleColumn({
    field: "requestStatus_AsString",
    headerText: "Request Status",
    type: "string",
  }),
  {
    field: "residentFullName",
    headerText: "Resident Full Name",
    type: "string",
  } as GridColumn,
  {
    field: "spouseFullName",
    headerText: "Spouse Full Name",
    type: "string",
  } as GridColumn,
  {
    field: "applicationDueDate_AsDateOnly",
    headerText: "File Due Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  } as GridColumn,
  new GridStyleColumn({
    field: "receivedStatus_AsString",
    headerText: "Received Status",
    type: "string",
  }),
  new GridStyleFIARequestDueInColumn({
    field: "dueIn",
    headerText: "Due In",
    type: "number",
  }),
  {
    field: "institutionType_AsString",
    headerText: "Institution Type",
    type: "string",
  } as GridColumn,
  {
    field: "institutionSendingContactMethod_AsString",
    headerText: "Send Method",
    type: "string",
  } as GridColumn,
  {
    field: "institutionAccountNumbers",
    headerText: "Account Number",
    type: "string",
  } as GridColumn,
  {
    field: "createdBy_AsName",
    headerText: "Created By",
    type: "string",
  } as GridColumn,
  {
    field: "applicationId",
    headerText: "Application ID",
    type: "string",
  } as GridColumn,
  {
    field: "id",
    headerText: "FIA Request ID",
    type: "string",
  } as GridColumn,
];
